import { useState } from "react";
import styled from "styled-components";
import GetStartedPopup from "./GetStartedPopup";

const Nav = styled.nav`
  #menu-button {
    z-index: 10;
  }

  #menu-button button {
    background: none;
    height: auto;
    width: auto;
    padding: 0;
  }

  #menu-button {
    display: none;
  }

  @media screen and (max-width: 1000px) {
    #menu-button {
      display: block;
    }
  }

  height: 86px;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  padding: 0 30px;
  box-sizing: border-box;

  .wrap {
    display: flex;
    align-items: center;
  }

  ul {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
  }

  ul a {
    text-decoration: none;
    color: black;
  }

  ul li {
    color: #2f3630;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    list-style-type: none;
    position: relative;
    padding-left: 15px;
    margin-right: 25px;
    cursor: pointer;
  }

  ul li:hover {
    opacity: 0.8;
  }
  ul li:active {
    opacity: 0.5;
  }

  ul li::before {
    content: "";
    position: absolute;
    left: 0;
    top: 56%;
    transform: translateY(-50%);
    width: 7px;
    height: 7px;
    background-color: green;
    border-radius: 50%;
    box-shadow: 0 0 0 1px white;
  }

  .right {
    display: flex;
    align-items: center;
  }

  @media screen and (max-width: 1000px) {
    .right {
      display: none;
    }
    .right.is-active {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 5;
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 50px 30px;
      background: #eaf3e6;
      opacity: 0;
      animation: fadeIn 0.3s forwards;
      box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.3);
    }
    .right.is-active ul {
      flex-direction: column;
      margin-bottom: 30px;
    }
    .right.is-active ul li {
      margin: 7px 0;
    }
  }

  button.get-started {
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
    width: 127px;
    height: 30px;
  }

  .get-started {
    box-shadow: 0px 4px 4px 0px rgba(47, 54, 48, 0.2);
  }

  #logo-icon {
    display: none;
  }

  @media screen and (max-width: 430px) {
    #logo-nav {
      width: 76%;
      position: relative;
      left: -10%;
      top: 1px;
    }
  }
`;

const NavComponent = ({}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [getStartedOpen, setGetStartedOpen] = useState(false);

  const handleGetStartedClick = () => {
    setGetStartedOpen(true);
    setMenuOpen(false);
  };

  const handleContactClick = () => {
    setGetStartedOpen(true);
    setMenuOpen(false);
  };

  return (
    <>
      <Nav>
        <div className="wrap">
          <a href="/">
            <img
              id="logo-nav"
              className="hover-effect"
              src="images/logo-nav.svg"
              alt="zendo logo"
            />
            {/* <img
            id="logo-icon"
            className="hover-effect"
            src="images/logo-icon.svg"
            alt="zendo logo"
          /> */}
          </a>
        </div>
        <div id="nav-menu" className={`right ${menuOpen && "is-active"}`}>
          <ul>
            <li><a href="/locations">Locations</a></li>
            <li><a href="/counselors">Counselors</a></li>
            <li><a href="/contact">Contact</a></li>
          </ul>
          {/* <button className="get-started" onClick={handleGetStartedClick}>Get started</button> */}
        </div>
        <div id="menu-button">
          <button
            onClick={() => setMenuOpen(!menuOpen)}
            className={`hamburger hamburger--collapse ${
              menuOpen && "is-active"
            }`}
            type="button"
          >
            <span className="hamburger-box">
              <span className="hamburger-inner"></span>
            </span>
          </button>
        </div>
        {getStartedOpen && (
          <GetStartedPopup onClose={() => setGetStartedOpen(false)} />
        )}
      </Nav>
    </>
  );
};

export default NavComponent;
