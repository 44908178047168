import { useState } from "react";
import styled from "styled-components";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import FadeInOnScroll from "../components/FadeInOnScroll";
import CallToAction from "../components/CallToAction";
import IntroBand from "../components/IntroBand";
import GetStartedPopup from "../components/GetStartedPopup";


const ArrowLink = styled.a`
  color: #5e8a64;
  font-size: 30px;
  font-weight: 600;
  line-height: 1.3;

  position: relative;
  padding-right: 40px;
  cursor: pointer;
  text-decoration: none;

  &::after {
    content: url("../images/arrow.svg");
    position: absolute;
    right: 0;
    top: 40%;
    transform: translateY(-50%);
    transition: right ease 0.4s;
  }

  &:hover {
    opacity: 0.8;
    &::after {
      right: -10px;
    }
  }
  &:active {
    opacity: 0.5;
  }
`;

const WellnessStyles = styled.div`
  #wellness-cta {
    padding: 118px 30px 100px;
    width: fit-content;
  }

  #wellness-cta .wrap {
    border-radius: 16px;
    background: rgba(255, 255, 255, 0.8);
    padding: 76px 144px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  @media screen and (max-width: 750px) {
    #wellness-cta .wrap {
      padding: 76px 51px;
    }
  }

  #wellness-cta h2 {
    font-size: 26px;
  }
  #wellness-cta p {
    font-size: 18px;
    width: 671px;
    margin: 30px 0;
    max-width: 100%;
  }
`;

function Wellness() {

  const [menuOpen, setMenuOpen] = useState(false);
  const [getStartedOpen, setGetStartedOpen] = useState(false);

  const handleGetStartedClick = () => {
    setGetStartedOpen(true);
    setMenuOpen(false);
  };

  const handleContactClick = () => {
  };

  return (
    <FadeInOnScroll>
      <div className="green-bg">
        {getStartedOpen && (
          <GetStartedPopup onClose={() => setGetStartedOpen(false)} />
        )}
      </div>
      <WellnessStyles className="garden-bg">
        <section id="wellness-cta">
          <div className="wrap scroll-fade-in">
            <h2>Your Wellness Journey Begins Here</h2>
            <p>
              Are you ready to take the next step? <br></br>
              You can fill out the contact form or give us a phone call. <br></br>
              During normal business hours a person will answer the phone 95% of the time.
            </p>
            <ArrowLink href="/Contact">Contact Us</ArrowLink>
            </div>
        </section>
      </WellnessStyles>
    </FadeInOnScroll>
  );
}

export default Wellness;