import React from "react";
import styled from "styled-components";

const Wrap = styled.div``;

const Content = () => {
  return (
    <>
      <Wrap>
      <h2>Introduction</h2>
        <p>
        Stephanie is a Marriage & Family Therapist Associate who earned her master’s after nearly a decade of service to individuals, children, and families in a variety of roles, from Doula to Family Support Specialist. Walking with families through loss and life hardship imparted on Stephanie a deep care and understanding for those recovering from developmental and complex trauma, who are seeking a brighter life forward, not only for themselves but for their children.</p>
        <p>
        Stephanie believes that by nurturing and healing the individual, families will thrive, and that when families thrive, the entire world benefits. She understands that deep healing involves addressing mind, body, and soul. Through an integrative approach to therapy, she utilizes Attachment-based models, Interpersonal Neurobiology, Sensorimotor Psychotherapy, Internal Family Systems, and Mindfulness-based interventions to tailor therapy to the individual. Stephanie has particular interest and experience with supporting individuals in the processing of their developmental trauma, as well as in supporting individuals and families through the perinatal period, first five years of parenting, and in navigating restoration in parent/adult-child relationships.           
        </p>
        <p>
        Outside of therapy, Stephanie loves reading, adventures with her family, yoga, novelty, and connecting with others. 
        </p>
        <br />
        <br />
      </Wrap>
    </>
  );
};

export default Content;
