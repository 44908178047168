import React, { useState } from "react";
import styled from "styled-components";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import FadeInOnScroll from "../components/FadeInOnScroll";
import CallToAction from "../components/CallToAction";
import IntroBand from "../components/IntroBand";

const Hero = styled.div`
  z-index: 3;
  position: relative;

  nav {
    z-index: 3;
    position: relative;
  }
`;

const Title = styled.div`
  font-family: Raleway;
  font-size: 40px;
  font-weight: 400;
  line-height: 1.3;
  text-align: left;
  color: black;
`;

const Description = styled.div`
  font-family: Raleway;
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  text-align: left;
  padding: 10px 0;
  color: black;
`;

function Terms() {

  return (
    <div>
      <main>
        <div className="green-bg">
          <Hero>
            <Nav />
            <section id="hero">
              <div className="wrap">
                <br/><br/>
                <Title >Terms and Conditions</Title>
                <br/>
                <Description >Effective Date: 1/1/2025<br/>
                  Welcome to Zendo Health (the "Site"). These Terms and Conditions ("Terms") govern your use of our Site and services. By accessing or using the Site,
                  you agree to comply with and be bound by these Terms. If you do not agree to these Terms, please do not use our Site.
                  1. Acceptance of Terms</Description>
                <Description >By using the Site, you acknowledge that you have read, understood, and agree to be bound by these Terms, 
                  as well as our <a href="https://zendo.health/privacy">Privacy Policy</a>. 
                  If you are using the Site on behalf of an organization, 
                  you represent that you have the authority to bind that organization to these Terms.</Description>
                <Description>SMS Terms & Conditions</Description>                
                <Description >SMS Consent Communication:<br/>
                  The information (Phone Numbers) obtained as part of the SMS consent process will not be shared with third parties for marketing purposes.</Description>
                <Description >Types of SMS Communications:<br/>
                  If you have consented to receive text messages from [Company Name], you may receive messages related to the following (provide specific examples):<br/>
                  - Appointment reminders<br/>
                  - Follow-up messages</Description>
                <Description>Example: "Hello, this is a friendly reminder of your upcoming appointment with [Name] at [Location] on [Date] at [Time]. 
                  Reply STOP to opt out of SMS messaging at any time." <br/><br/>
                  Message Frequency:
                  Message frequency may vary depending on the type of communication. For example, you may receive up to 2 SMS messages per week related to your appointments.<br/><br/>
                  Example:<br/>
                  "Message frequency may vary. You may receive up to 2 SMS messages per week regarding your appointments or account status."</Description>
                <Description>Potential Fees for SMS Messaging:<br/>
                  Please note that standard message and data rates may apply, depending on your carrier’s pricing plan. These fees may vary if the message is sent domestically or internationally.</Description>
                <Description>Opt-In Method:<br/>
                  You may opt-in to receive SMS messages from [Company Name] in the following ways:<br/>
                  - Verbally, during a conversation<br/>
                  - By submitting an online form<br/>
                  - By filling out a paper form</Description>
                <Description>Opt-Out Method:<br/>
                  You can opt-out of receiving SMS messages at any time. To do so, simply reply "STOP" to any SMS message you receive. 
                  Alternatively, you can contact us directly to request removal from our messaging list.</Description>
                <Description>Help:<br/>
                  If you are experiencing any issues, you can reply with the keyword HELP. Or, you can get help directly by calling 1-800-433-0262 or by visiting our website at <a href="https://zendo.health">https://Zendo.Health</a>
                </Description>
                <Description>Additional Options:<br/>
                  - If you do not wish to receive SMS messages, you can choose not to check the SMS consent box on our forms.</Description>
                <Description>Standard Messaging Disclosures:<br/>
                  - Message and data rates may apply.<br/>
                  - You can opt out at any time by texting "STOP."<br/>
                  - For assistance, text "HELP" or visit our <a href="https://zendo.health/privacy">Privacy Policy</a> and <a href="https://zendo.health/terms">Terms and Conditions</a> pages.<br/>
                  - Message frequency may vary.</Description>
                  <Description>2. Limitations of Liability<br/>
                  In no event shall Zendo Health, its officers, directors, employees, or agents be liable for any indirect, incidental, special, consequential, 
                  or punitive damages, including but not limited to loss of profits, data, or usage, 
                  arising out of or related to your use of the Site or any services provided by Zendo Health.</Description>
                  <Description>3. Changes to Terms<br/>
                    Zendo Health reserves the right to modify these Terms at any time without prior notice. 
                    Your continued use of the Site following any changes signifies your acceptance of the new Terms.</Description>
                  <Description>4. Governing Law<br/>
                    These Terms shall be governed by and construed in accordance with the laws of Oregon, Utah, or Kansas. 
                    Any disputes arising out of or related to these Terms shall be resolved in the courts located in Oregon, Utah, or Kansas respectively.</Description>
                  <Description>5. Contact Information<br/>
                    For any questions about these Terms, please contact us at:<br/>
                    Zendo Health<br/>
                    76 South 360 East<br/>
                    American Fork, UT 84003<br/>
                    Email: info@zendo.health<br/>
                    Phone: 1-800-433-0262</Description>

                <br/><br/>
              </div>
            </section>
          </Hero>
        </div>
        <CallToAction />
      </main>
      <Footer />
    </div>
  );

}

export default Terms;
