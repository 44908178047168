import React, { useState } from "react";
import styled from "styled-components";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import FadeInOnScroll from "../components/FadeInOnScroll";
import CallToAction from "../components/CallToAction";
import IntroBand from "../components/IntroBand";

const Hero = styled.div`
  z-index: 3;
  position: relative;

  nav {
    z-index: 3;
    position: relative;
  }
`;

const Title = styled.div`
  font-family: Raleway;
  font-size: 40px;
  font-weight: 400;
  line-height: 1.3;
  text-align: left;
  color: black;
`;

const Description = styled.div`
  font-family: Raleway;
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  text-align: left;
  padding: 10px 0;
  color: black;
`;


const DescriptionBold = styled.div`
  font-family: Raleway;
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
  text-align: left;
  padding: 10px 0;
  color: black;
`;


function Privacy() {

  return (
    <div>
      <main>
        <div className="green-bg">
          <Hero>
            <Nav />
            <section id="hero">
              <div className="wrap">
                <br/><br/>
                <Title >Privacy Policy</Title>
                <br/>
                <Description >We abide by the American Counseling Association and the State of Oregon Code of Ethics regarding
                  confidentiality. With the exceptions described below, you have the absolute right to the confidentiality of
                  your therapy. We cannot and will not tell anyone else what you have told any counselor in Oregon
                  Directions, or even that you are in therapy with us, without your prior written permission. Under the
                  provisions of the Health Care Information Act of 1992, we may legally speak to another health care provider
                  or if you are a minor in counseling, a member of your family about you without your prior consent.
                  However, we will not release any information about you to anyone, unless the situation is an emergency or
                  if you choose to sign a written release allowing us to share information to someone you designate. You
                  may direct me to share information with whomever you choose, and you can change your mind and revoke
                  that permission at any time. You may request anyone you wish to attend a therapy session with you.</Description>
                                  <Description >HIPAA:<br/>You are also protected under the provisions of the Federal Health Insurance Portability and Accountability
                  Act (HIPAA). This law insures the confidentiality of all electronic transmission of information about you.
                  Whenever I transmit information about you electronically (for example, sending bills or faxing information),
                  it will be done with special safeguards to insure confidentiality.</Description>
                                  <Description >Communications:<br/>If you elect to communicate by email at some point in our work together, please be aware that email is not
                  completely confidential. All emails are retained in the logs of your and my internet service provider. While
                  under normal circumstances no one looks at these logs, they are, in theory, available to be read by the
                  system administrator(s) of the internet service provider. Any email I receive from you, and any responses
                  that I send to you, will be printed out and kept in your treatment record.</Description>
                  <Description >Video conferencing, email, fax, and phone<br/>
                  Video conferencing, email, fax, and phone are not a confidential means of communication and are
                  subject to state and Federal laws regarding confidentiality. Our video conferencing, email, fax, phone communications to you use standard encryption transmission methods. </Description>
                  <Description>SMS:<br/>SMS consent will
                  not be shared with any third party and affiliates for marketing, nor will any phone numbers for SMS
                  purposes. You can view our <a href="https://zendo.health/terms">Terms and Conditions</a></Description>

                                  <Description >Information disclosed to any counselor at Zendo Health (formerly Oregon Directions) is privileged
                  communication and cannot be disclosed in any court of competent jurisdiction in the State of Oregon
                  without the consent of the person to whom the testimony sought relates. There are exceptions to the
                  general rule of legal confidentiality. These exceptions are listed in the Oregon statutes (ORS 675.715 to
                  675.835). You should be aware that provisions concerning disclosure of confidential communications does
                  not apply to any delinquency or criminal proceeding, except as provided in Oregon law. Some of these
                  exceptions include: serious threat or intent to harm others or yourself; abuse or neglect of children; abuse or
                  suspected abuse of the elderly or others unable to care for themselves; subpoenaed testimony in criminal
                  court cases; and orders to violate confidential privilege by judges in child-custody, divorce, and/or other
                  court cases.</Description>
                                  <Description >The following are legal exceptions to your right to confidentiality. We will inform you of any time when we
                  think we will have to put any of these into effect.
                  <br/>1. If any counselor has good reason to believe that you will harm another person, he or she must attempt to
                  inform that person and warn them of your intentions. We must also contact the police and ask them to
                  protect your intended victim.
                  <br/>2. If any counselor has good reason to believe that you are abusing or neglecting a child or vulnerable
                  adult, of if you give us information about someone else who is doing this, he or she must inform Child
                  Protective Services or other authorities immediately.
                  <br/>3. If any counselor believes that you are an imminent danger of harming yourself, the counselor may legally
                  break confidentiality and call the police or a local crisis team. We are not obligated to do this and would
                  explore all other options with you before we took this step. If at that point you were unwilling to take steps
                  to guarantee your safety, we would call the crisis team or the police.
                  <br/>4. If requested, we will share diagnosis and dates of service with your insurance company. We will help you
                  fill out a reimbursement form for you to be reimbursed for the counseling fee that you have already paid to
                  us.
                  <br/>5. If you tell us of the behavior of another named health or mental health care provider that informs us that
                  this person has either: a) engaged in sexual contact with a patient, including yourself, or b) is impaired from
                  practice in some manner by cognitive, emotional, behavioral, or health problems, then the law requires us
                  to report this to their licensing board. We would inform you before taking this step. If you are our client
                  and a health care provider, however, your confidentiality remains protected under the law from this kind of
                  reporting.
                  <br/>6. Any other report mandated by Oregon law.</Description>
                                  <Description >This is not a legal exception to your confidentiality. However, it is a policy you should be aware of if
                  you are in couples therapy with us.
                  If you decide to have some individual sessions as part of family therapy, what you say in those individual
                  sessions will be considered to be a part of the family therapy and can and probably will be discussed in
                  your joint sessions. Do not tell us anything you wish kept secret from your partner or other members of
                  your family attending counseling sessions. We will remind you of this policy before beginning such
                  individual sessions.</Description>
                                  <Description >Supervision and Consultation:<br/>
                  It is ethical and common practice for psychotherapists to consult with other professionals or colleagues
                  about issues that arise within therapy. Your confidentiality will still be protected during consultation and
                  supervision sessions. We will provide specific information if supervision and/or consultation is needed to
                  provide professional services to you, our client.</Description>
                                  <Description >Record-Keeping:<br/>
                  We keep records, noting only that you have been here, what interventions happened in session, and the
                  topics discussed. Some insurers require us to keep session records. If you prefer that we keep no records,
                  you must give us a written request to this effect. Under the provisions of the Health Care Information Act,
                  you have the right to a copy of your file at any time. You have the right to request that we correct any
                  errors in your file. You have the right to request that we make a copy of your file available to any other
                  health care provider at your written request. You may be charged a minor copying and mailing fee for
                  those services. We maintain your records in a secure location that cannot be accessed by anyone else.</Description>
                                  <Description >Diagnosis:<br/>
                  If a third party such as an insurance company is paying for part of your bill, we are normally required to
                  give a diagnosis to that third party in order to be paid. Diagnoses are technical terms that describe the
                  nature of your problems and something about whether they are short-term or long-term problems. If we
                  do use a diagnosis, we will discuss it with you. All of the diagnoses come from a book titled Diagnostic and
                  Statistical Manual of Mental Disorders, Fifth Edition (DSM-5-TR). We have copies in our office and will be
                  glad to show it to you, so you can learn more about what it says about your diagnosis.</Description>
                                  <Description >Other Rights:<br/>
                  You have the right to ask questions about anything that happens in therapy. We are always willing to
                  discuss how and why your counselor has decided to do what they are doing. You can feel free to ask us to
                  try something that you think will be helpful. You can ask us about any counselor's training for working with
                  your concerns. You can request that your counselor refer you to someone else if you decide that he or she
                  is not the right therapist for you. You are free to leave therapy at any time, although we recommend finding
                  a way to give us some advance notice so we can help you end treatment well and consolidate gains. (See
                  the section below on Ending Therapy)</Description>
                                  <Description >Because Zendo Health (formerly Oregon Directions) has a limited practice, we do not have 24-hour
                  emergency or "on call" coverage. If you believe you need a therapist with 24-hour coverage, we will be
                  happy to make a referral. If you experience a psychiatric emergency, you should call 911 or go to the
                  nearest hospital emergency room rather than wait for any counselor at Oregon Directions to call you back.<br/>
                  When your counselor is away from the office for an extended period of time, he or she will give you the
                  name of a colleague you can contact in case of an urgent need. Counselors may be away from the office
                  several times each year for vacations or to attend professional development meetings or seminars. Your
                  counselor will tell you in advance of any anticipated lengthy absences. The counselor will also leave a
                  message on his or her office telephone to inform you of their return date and the contact information for
                  the colleague covering for them during each absence.</Description>
                <br/><br/>
              </div>
            </section>
          </Hero>
        </div>
        <CallToAction />
      </main>
      <Footer />
    </div>
  );

}

export default Privacy;
